<template>
  <b-alert dismissible :variant="variant" :show="mostrarAlerta" @dismissed="ocultarAlerta">
    {{ texto }}
  </b-alert>
</template>
<script>
export default {
  props: {
    texto: String,
    mostrarAlerta: Boolean,
    variant: String,
  },
  methods: {
    ocultarAlerta() {
      this.$emit("ocultar");
    },
  },
};
</script>
